<template>
 <div>
  <v-card max-width="370" class="mx-auto mt-5" flat>
    <Errors v-bind:errors="errors"/>

  <v-card-title>
    <h4>Meine Informationen bearbeiten</h4>
  </v-card-title>
  <v-card-text>
    <v-form @submit.prevent v-model="valid">
        
      <!-- Firstname -->
      <v-text-field 
      type="text"
      label="Vorname"
      v-model.trim="firstname"
      />

      <!-- Lastname -->
      <v-text-field 
      type="text"
      label="Nachname"
      v-model.trim="lastname"
      :rules="requiredRule"
      required
      />

      <!-- Phone -->
      <v-text-field 
      type="numbers"
      label="Telefonnummer"
      v-model.trim="phone"
      />

      <!-- Email im Profil anzeigen -->
      <v-switch
      inset
      label="E-Mail Adresse im Profil anzeigen"
      v-model="showEmail"
      ></v-switch>

      <!-- Information -->
      <v-textarea
      outlined
      auto-grow
      counter="200"
      :rules="counterRule"
      background-color="accent"
      label="Informationen"
      v-model.trim="info"
      ></v-textarea>


    </v-form>

  </v-card-text>

  <v-card-actions>
      <v-container>
          <v-row class="my-2 mt-8">
              <v-btn
                color="secondary"
                width="100%"
                depressed
                @click="$router.push('/teacher/' + teacherId)">
                Abbrechen
              </v-btn>
          </v-row>
          <v-row class="my-2">
              <v-btn
              :disabled="!valid"
              width="100%"
              color="primary"
              depressed
              @click="update"
              >
                Aktualisieren
              </v-btn>
          </v-row>
        </v-container>
        
    </v-card-actions>

  </v-card>
</div> 
</template>

<script>
import { mapState } from 'vuex';
import { auth, teachersCollection } from '@/firebase';
import Errors from '@/components/Errors.vue';

export default {
  name: 'EditTeacher',
  props: ['teacherId'],

  components: {
    Errors,
  },

  data() {
    return {
      valid: false,

      /* rules for the form */
      requiredRule: [
        v => !!v || 'Pflichtfeld',
      ],
      counterRule: [
        v => (v || '' ).length <= 200 || 'Gib maximal 200 Zeichen ein'
      ],

      firstname: '',
      lastname: '',
      phone: '',
      showEmail: false,
      info: '',
      errors: [],
    }
  },

  computed: {
    ...mapState(['isAdmin']),
  },

  async created() {
    this.getTeacher();
  },

  methods: {

    async getTeacher(){

      if (auth.currentUser.uid == this.teacherId || this.isAdmin){
        const teacherDoc = await teachersCollection.doc(this.teacherId).get();
        if (teacherDoc.exists) {
          let teacher = teacherDoc.data();
          this.firstname = teacher.firstname;
          this.lastname = teacher.lastname;
          if(teacher.phone){ //could be empty (avoid to make this.phone undefined)
            this.phone = teacher.phone;
          }
          this.showEmail = teacher.showEmail;
          if(teacher.info){ //could be empty (avoid to make this.info undefined)
            this.info = teacher.info;
          }
        } else {
          console.log('Could not find this document')
        }
      } else {
        console.log('Wenn du kein Admin bist, kannst du nur dich selbst bearbeiten.');
        this.$router.replace('/errorrole');
      }
    },

    update() {
      //check if current user == this user
      if ((auth.currentUser.uid == this.teacherId || this.isAdmin) && this.valid){

        try{
          //update everything in teachersCollection
          teachersCollection
            .doc(this.teacherId)
            .update({
              firstname: this.firstname,
              lastname: this.lastname,
              phone: this.phone,
              showEmail: this.showEmail,
              info: this.info
          })

        } catch(error) {
          console.log("Error updating documents: ", error.message);
          this.errors.push({
            text: 'Die Informationen konnten nicht gespeichert werden.', 
            type:'firestore',
          });
          window.scrollTo(0,0);
        }

        if (!this.errors.length){
          this.$router.push('/teacher/' + this.teacherId);
        }
          
      }
    }

  }

}
</script>

<style>
/* dont allow line breaks within a word in the title */
.v-card__title {
  word-break: normal; 
}

/* workarround for bugfix: on iOS the cursor is invisible when first clicking into the field, see https://github.com/vuetifyjs/vuetify/issues/13628 */
.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>